import {HttpContextToken} from "@angular/common/http";

export class Constants {
  public static ACCESS_TOKEN = () => localStorage.getItem('accessToken');
  public static TOKEN_REQUIRED = new HttpContextToken(() => true);
  public static paginationSizes = [5, 10, 20];
  public static paginationSizeDefault = 10;
}

export enum Permissions {
  SuperAdmin = "SuperAdmin",
  UserCustomerManagement = "UserCustomerManagement",
  UserExpertManagement = "UserExpertManagement",
  VoucherProviderManagement = "VoucherProviderManagement",
  VoucherBuyListManagement = "VoucherBuyListManagement",
  VoucherInventorManagement = "VoucherInventorManagement",
  ExchangeRate = "ExchangeRate",
  UserBankAccount = "UserBankAccount",
  Banks = "Banks",
  KycProvider = "KycProvider",
  CustomerKyc = "CustomerKyc",
}
